<template>
  <div :class="containerClass" @click="onWrapperClick">
    <template v-if="!isLoginPage && userService.isLoggedIn()">
      <AppTopBar @menu-toggle="onMenuToggle"/>

      <transition name="layout-sidebar">
        <div :class="sidebarClass" @click="onSidebarClick" v-show="isSidebarVisible()">
          <div class="layout-logo">
            <router-link to="/">
              <img alt="Logo" src="/assets/layout/images/de-tafelheer-logo.png" width="200"/>
            </router-link>
          </div>

          <AppProfile/>
          <AppMenu :model="menu" @menuitem-click="onMenuItemClick"/>
        </div>
      </transition>
    </template>

    <div class="layout-main">
      <router-view/>
    </div>

    <AppFooter/>

    <OrderListDialog ref="orderListDialog"/>
    <Toast/>
  </div>
</template>

<script>
import UserService from './service/UserService'
import OrderService from './service/OrderService'
import OrderListService from './service/OrderListService'

import AppTopBar from './AppTopbar.vue'
import AppProfile from './AppProfile.vue'
import AppMenu from './AppMenu.vue'
import AppFooter from './AppFooter.vue'
import OrderListDialog from './components/OrderListDialog'

export default {
  data() {
    return {
      layoutMode: 'static',
      layoutColorMode: 'light',
      staticMenuInactive: false,
      overlayMenuActive: false,
      mobileMenuActive: false,
      menu: [
        {label: 'Dashboard', icon: 'pi pi-fw pi-home', to: '/'},
        {label: 'Mijn bestellingen', icon: 'pi pi-fw pi-align-center', to: '/bestellingen'},
        {label: 'Alle producten', icon: 'pi pi-fw pi-sitemap', to: '/producten'},
        {
          label: 'Bestellijsten', icon: 'pi pi-fw pi-list',
          items: [
            {
              label: 'Nieuwe bestellijst', icon: 'pi pi-fw pi-plus', command: () => {
                this.$refs.orderListDialog.orderListDialog = true
              }
            }
          ]
        }
      ],
      orderLists: null
    }
  },
  userService: null,
  orderService: null,
  orderListService: null,
  created() {
    this.userService = new UserService()
    this.orderService = new OrderService()
    this.orderListService = new OrderListService()
  },
  mounted() {
    if (this.userService.isLoggedIn()) {
      this.updateOrderList()
    }
  },
  watch: {
    $route() {
      if (this.userService.isAdmin()) {
        const adminMenu = this.menu.find(menu => {
          return menu.uid && menu.uid === 'admin-menu'
        });

        if (!adminMenu) {
          this.menu.push({
            uid: 'admin-menu',
            label: 'Admin', icon: 'pi pi-fw pi-desktop',
            items: [
              {uid: 'admin-orders', label: 'Bestellingen', icon: 'pi pi-fw pi-align-center', to: '/admin/orders'},
              {label: 'Importeer producten', icon: 'pi pi-fw pi-cloud-upload', to: '/admin/product-import'},
              {label: 'Gebruikers', icon: 'pi pi-fw pi-user-edit', to: '/admin/gebruikers'}
            ]
          })

          this.updateAdminMenu()
          setInterval(() => {
            this.updateAdminMenu()
          }, 60000)
        }
      }
    }
  },
  methods: {
    updateOrderList() {
      const items = [{
        label: 'Nieuwe bestellijst', icon: 'pi pi-fw pi-plus', command: () => {
          this.$refs.orderListDialog.orderListDialog = true
        }
      }]

      this.orderListService.getOrderLists().then(data => {
        this.orderLists = data
        data.forEach(orderList => {
          items.push({
            label: orderList.name, icon: 'pi pi-fw pi-bars', to: `/bestellijst/${orderList.id}`
          })
        })

        this.menu.find((o, i) => {
          if (o.label === 'Bestellijsten') {
            this.$root.menu[i].items = items
            return true
          }
        })
      })
    },
    updateAdminMenu() {
      this.orderService.getCountUncompleted().then(data => {
        this.menu.find((o, i) => {
          if (o.uid === 'admin-menu') {
            this.$root.menu[i].label = `Admin <span class="menuitem-badge">${data.count}</span>`

            this.$root.menu[i].items.find((a, j) => {
              if (a.uid === 'admin-orders') {
                this.$root.menu[i].items[j].label = `Bestellingen <span class="menuitem-badge">${data.count}</span>`
                return true
              }
            })

            return true
          }
        })
      })
    },
    onWrapperClick() {
      if (!this.menuClick) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }

      this.menuClick = false;
    },
    onMenuToggle() {
      this.menuClick = true;

      if (this.isDesktop()) {
        if (this.layoutMode === 'overlay') {
          if (this.mobileMenuActive === true) {
            this.overlayMenuActive = true;
          }

          this.overlayMenuActive = !this.overlayMenuActive;
          this.mobileMenuActive = false;
        } else if (this.layoutMode === 'static') {
          this.staticMenuInactive = !this.staticMenuInactive;
        }
      } else {
        this.mobileMenuActive = !this.mobileMenuActive;
      }

      event.preventDefault();
    },
    onSidebarClick() {
      this.menuClick = true;
    },
    onMenuItemClick(event) {
      if (event.item && !event.item.items) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }
    },
    onLayoutChange(layoutMode) {
      this.layoutMode = layoutMode;
    },
    onLayoutColorChange(layoutColorMode) {
      this.layoutColorMode = layoutColorMode;
    },
    addClass(element, className) {
      if (element.classList)
        element.classList.add(className);
      else
        element.className += ' ' + className;
    },
    removeClass(element, className) {
      if (element.classList)
        element.classList.remove(className);
      else
        element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    },
    isDesktop() {
      return window.innerWidth > 1024;
    },
    isSidebarVisible() {
      if (this.isDesktop()) {
        if (this.layoutMode === 'static')
          return !this.staticMenuInactive;
        else if (this.layoutMode === 'overlay')
          return this.overlayMenuActive;
        else
          return true;
      } else {
        return true;
      }
    },
  },
  computed: {
    isLoginPage() {
      return this.$route.name === 'login'
    },
    containerClass() {
      return ['layout-wrapper', {
        'layout-overlay': this.layoutMode === 'overlay',
        'layout-static': this.layoutMode === 'static',
        'layout-static-sidebar-inactive': this.isLoginPage || (this.staticMenuInactive && this.layoutMode === 'static'),
        'layout-overlay-sidebar-active': this.overlayMenuActive && this.layoutMode === 'overlay',
        'layout-mobile-sidebar-active': this.mobileMenuActive,
        'p-input-filled': this.$appState.inputStyle === 'filled',
        'p-ripple-disabled': this.$primevue.config.ripple === false
      }];
    },
    sidebarClass() {
      return ['layout-sidebar', {
        'layout-sidebar-dark': this.layoutColorMode === 'dark',
        'layout-sidebar-light': this.layoutColorMode === 'light'
      }];
    },
    logo() {
      return (this.layoutColorMode === 'dark') ? "assets/layout/images/logo-white.svg" : "assets/layout/images/logo.svg";
    }
  },
  beforeUpdate() {
    if (this.mobileMenuActive)
      this.addClass(document.body, 'body-overflow-hidden');
    else
      this.removeClass(document.body, 'body-overflow-hidden');
  },
  components: {
    'AppTopBar': AppTopBar,
    'AppProfile': AppProfile,
    'AppMenu': AppMenu,
    'AppFooter': AppFooter,
    'OrderListDialog': OrderListDialog,
  }
}
</script>

<style lang="scss">
@import './App.scss';
</style>
