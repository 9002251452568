import axios from 'axios'

export default class OrderService {
    getOrders() {
        return axios.get(`/order/list`).then(res => res.data)
    }

    getRecentOrders() {
        return axios.get(`/order/list?limit=10`).then(res => res.data)
    }

    newOrder(data) {
        return axios.post(`/order/new`, data)
    }

    // > ADMIN
    getAdminOrders() {
        return axios.get(`/admin/order/list`).then(res => res.data)
    }

    getCountUncompleted() {
        return axios.get(`/admin/order/count-uncompleted`).then(res => res.data)
    }

    updateStatus(data) {
        return axios.post(`/admin/order/update-status`, data)
    }
    // < ADMIN
}
