<template>
  <Dialog v-model:visible="orderListDialog" :style="{width: '450px'}" :header="(orderList.id ? 'Wijzig' : 'Nieuwe') + ' bestellijst'" :modal="true" class="p-fluid">
    <div class="p-field">
      <label for="name">Naam</label>
      <InputText id="name" v-model.trim="orderList.name" required="true" autofocus
                 :class="{'p-invalid': submitted && (!orderList.name || orderList.name && orderList.name.length > 30)}"/>
      <small class="p-invalid" v-if="submitted && !orderList.name">Naam is verplicht.</small>
      <small class="p-invalid" v-if="orderList.name && orderList.name.length > 30">Naam mag niet langer zijn dan 30 karakters.</small>
    </div>

    <template #footer>
      <Button label="Cancel" icon="pi pi-times" class="p-button-text" @click="orderListDialog = false"/>
      <Button label="Save" icon="pi pi-check" class="p-button-text" @click="saveOrderList"/>
    </template>
  </Dialog>
</template>

<script>
export default {
  data() {
    return {
      orderListDialog: false,
      orderList: {},
      submitted: false
    }
  },
  methods: {
    async saveOrderList() {
      this.submitted = true
      if (this.orderList.name.trim() && this.orderList.name.length <= 30) {
        if (this.orderList.id) {
          try {
            await this.$root.orderListService.editOrderList(this.orderList)
            this.$root.updateOrderList()
            this.$toast.add({severity: 'success', summary: 'Successful', detail: 'Bestellijst aangepast', life: 3000});
          } catch (e) {
            this.$toast.add({severity: 'error', summary: 'Error', detail: 'Bestellijst niet aangepast'})
          }
        } else {
          try {
            const result = await this.$root.orderListService.newOrderList(this.orderList)
            this.$root.updateOrderList()
            this.$toast.add({severity: 'success', summary: 'Successful', detail: 'Bestellijst toegevoegd', life: 3000});
            await this.$router.push({ name: 'orderList', params: {id: result.data.id} })
          } catch (e) {
            this.$toast.add({severity: 'error', summary: 'Error', detail: 'Bestellijst niet toegevoegd'})
          }
        }
        this.orderListDialog = false
        this.orderList = {}
        this.submitted = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.customer-badge {
  border-radius: 2px;
  padding: .25em .5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: .3px;

  &.status-received {
    background: #C8E6C9;
    color: #256029;
  }

  &.status-in-progress {
    background: #FFD8B2;
    color: #805B36;
  }
}
</style>
