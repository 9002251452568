import axios from 'axios'

// const ROLE_USER = 'ROLE_USER'
const ROLE_ADMIN = 'ROLE_ADMIN'

export default class UserService {

	login(username, password) {
		return axios.post(`/login`, {
			username,
			password
		})
	}

	logout() {
		sessionStorage.clear()
	}

	saveUser(data) {
		sessionStorage.setItem('username', data.username)
		sessionStorage.setItem('apiToken', data.apiToken)
		sessionStorage.setItem('roles', data.roles)
		sessionStorage.setItem('logo', data.logo)

		axios.defaults.headers.common['X-AUTH-TOKEN'] = data.apiToken
	}

	getUser() {
		return {
			username: sessionStorage.getItem('username'),
			apiToken: sessionStorage.getItem('apiToken'),
			roles: sessionStorage.getItem('roles'),
			logo: sessionStorage.getItem('logo')
		}
	}

	isLoggedIn() {
		return sessionStorage.getItem('apiToken') !== null
	}

	// > ADMIN
	isAdmin() {
		return sessionStorage.getItem('roles') && sessionStorage.getItem('roles').includes(ROLE_ADMIN)
	}

	hasAdmin(data) {
		return data.roles.includes(ROLE_ADMIN)
	}

	getUsers() {
		return axios.get(`/admin/user/list`).then(res => res.data)
	}

	impersonateUser(data) {
		sessionStorage.setItem('impersonate', JSON.stringify(this.getUser()))
		this.saveUser(data)
		window.location.href = '/'
	}

	isImpersonating() {
		return !!sessionStorage.getItem('impersonate')
	}

	stopImpersonating() {
		const impersonate = sessionStorage.getItem('impersonate')
		if (!impersonate) {
			this.logout()
		}

		this.saveUser(JSON.parse(impersonate))
		sessionStorage.removeItem('impersonate')
		window.location.href = '/'
	}

	newUser(data) {
		return axios.post(`/admin/user/new`, data)
	}
	editUser(data) {
		return axios.post(`/admin/user/edit`, data)
	}
	deleteUser(data) {
		return axios.post(`/admin/user/delete`, data)
	}
	deleteUsers(data) {
		return axios.post(`/admin/user/delete-multiple`, data)
	}
	// < ADMIN
}
