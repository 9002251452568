<template>
  <div class="layout-profile">
    <div>
      <img v-if="getLogoUrl()" :src="getLogoUrl()"/>
    </div>
    <div v-if="$root.userService.isImpersonating()" :class="`impersonate-badge`" class="p-mb-3" @click="$root.userService.stopImpersonating()">
      Imiteren!
      <i class="pi pi-external-link"></i>
    </div>
    <button class="p-link layout-profile-link" @click="onClick">
      <span class="username">{{ $root.userService.getUser().username }}</span>
      <i class="pi pi-fw pi-cog"></i>
    </button>
    <transition name="layout-submenu-wrapper">
      <ul v-show="expanded">
        <li>
          <button class="p-link" @click="logout()"><i class="pi pi-fw pi-power-off"></i><span>Uitloggen</span></button>
        </li>
      </ul>
    </transition>

  </div>
</template>

<script>
export default {
  data() {
    return {
      expanded: false
    }
  },
  methods: {
    getLogoUrl() {
      const logo = this.$root.userService.getUser().logo
      if (logo === 'null') {
        return
      }

      return `${process.env.VUE_APP_BASE_URL}/upload${logo}`
    },
    logout() {
      this.$root.userService.logout();
      this.$router.push({name: 'login'})
    },
    onClick(event) {
      this.expanded = !this.expanded;
      event.preventDefault();
    }
  }
}
</script>

<style lang="scss" scoped>
.layout-profile {
  img {
    border-radius:50%
  }

  .impersonate-badge {
    cursor: pointer;
    border-radius: 2px;
    padding: .25em .5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: .3px;
    background: #FFD8B2;
    color: #805B36;
  }
}
</style>
