import axios from 'axios'

export default class OrderListService {
    getOrderLists() {
        return axios.get(`/order-list/list`).then(res => res.data)
    }

    getOrderList(id) {
        return axios.get(`/order-list/single?id=${id}`).then(res => res.data)
    }

    newOrderList(data) {
        return axios.post(`/order-list/new`, data)
    }

    editOrderList(data) {
        return axios.post(`/order-list/edit`, data)
    }

    deleteOrderList(data) {
        return axios.post(`/order-list/delete`, data)
    }

    addProductToOrderList(data) {
        return axios.post(`/order-list/add`, data)
    }

    removeProductFromOrderList(data) {
        return axios.post(`/order-list/remove`, data)
    }

    updateQuantity(data) {
        return axios.post(`/order-list/update-quantity`, data)
    }

}
