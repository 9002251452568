import {createRouter, createWebHistory} from 'vue-router'

const routes = [
    {
        path: '/',
        name: 'dashboard',
        component: () => import('./pages/Dashboard.vue'),
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('./pages/Login.vue'),
    },
    {
        path: '/producten',
        name: 'products',
        component: () => import('./pages/Products.vue')
    },
    {
        path: '/bestellingen',
        name: 'orders',
        component: () => import('./pages/Orders.vue')
    },
    {
        path: '/bestellijst/:id',
        name: 'orderList',
        component: () => import('./pages/OrderList.vue')
    },
    {
        path: '/admin/gebruikers',
        name: 'users',
        component: () => import('./pages/admin/Users.vue')
    },
    {
        path: '/admin/product-import',
        name: 'product-import',
        component: () => import('./pages/admin/ImportProducts.vue')
    },
    {
        path: '/admin/orders',
        name: 'admin-orders',
        component: () => import('./pages/admin/Orders.vue')
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes,
})

router.beforeEach((to, from, next) => {
    if (to.name !== 'login' && !sessionStorage.getItem('apiToken')) {
        next({name: 'login', params: {to: to.fullPath}})
    } else {
        next()
    }
})

export default router
